import { render, staticRenderFns } from "./customer-profit-detail.vue?vue&type=template&id=1dfbdd99"
import script from "./customer-profit-detail.vue?vue&type=script&lang=js"
export * from "./customer-profit-detail.vue?vue&type=script&lang=js"
import style0 from "./customer-profit-detail.vue?vue&type=style&index=0&id=1dfbdd99&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports